import { Config } from './types';

export const baseApiUri = process.env.REACT_APP_API_URL || 'https://inwf4fbyvm.eu-west-1.awsapprunner.com/api/v1';

export const config: Config = {
    name: process.env.REACT_APP_COMPANY_NAME || 'Defacto',
    logoUrl: process.env.REACT_APP_COMPANY_LOGO || 'https://imagedelivery.net/YV7tiPr1pNu8eWcEhMhZuA/4791bb48-6a18-41f7-cc3a-ee17b3cf9f00/public',
    primaryColor: process.env.REACT_APP_PRIMARY_COLOR || '#fff039',
    secondaryColor: process.env.REACT_APP_SECONDARY_COLOR || '#000',
    thirdColor: process.env.REACT_APP_THIRD_COLOR || '#000',
};
